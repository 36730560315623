var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("app-dropdownlist", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasMultipleOffices,
            expression: "hasMultipleOffices"
          }
        ],
        attrs: {
          id: "officeId",
          placeholder: _vm.$t("offices.office"),
          dataSource: _vm.offices,
          fields: { text: "name", value: "id" }
        },
        on: { input: _vm.setOfficeId },
        model: {
          value: _vm.data.officeId,
          callback: function($$v) {
            _vm.$set(_vm.data, "officeId", $$v)
          },
          expression: "data.officeId"
        }
      }),
      _c("app-textbox", {
        attrs: {
          id: "calendarTypeIdOld",
          type: "hidden",
          value: _vm.$options.calendarTypeId
        }
      }),
      _c("app-dropdownlist", {
        attrs: {
          id: "calendarTypeId",
          placeholder: _vm.$t("calendarTypes.calendarType"),
          dataSource: _vm.officeCalendarTypes,
          fields: { text: "name", value: "id" },
          enabled: _vm.isOfficeSelected
        },
        model: {
          value: _vm.data.calendarTypeId,
          callback: function($$v) {
            _vm.$set(_vm.data, "calendarTypeId", $$v)
          },
          expression: "data.calendarTypeId"
        }
      }),
      _c("app-dropdownlist-employee-calendar-type-visibility", {
        attrs: {
          id: "visibility",
          placeholder: _vm.$t("calendarTypes.visibility.visibility"),
          enabled: _vm.isOfficeSelected
        },
        model: {
          value: _vm.data.visibility,
          callback: function($$v) {
            _vm.$set(_vm.data, "visibility", $$v)
          },
          expression: "data.visibility"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }